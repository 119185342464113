import spaceXLogo from "../assests/images/spaceXLogo.jpg";
import RocketModal from "./RocketModal";
import { Row, Col, Image } from "react-bootstrap";
import { DateTime } from "luxon";

const LaunchDisplay = ({ launchData, selectedLaunch, selectedRocket }) => {
  let displayData = launchData[0];

  // Use selected launch data if it has been selected
  if (
    typeof selectedLaunch === "object" &&
    selectedLaunch !== null &&
    !Array.isArray(selectedLaunch)
  ) {
    displayData = selectedLaunch;
  }

  // Set date format
  const setDate = () => {
    if (launchData.length > 0) {
      let dt = DateTime.fromISO(displayData.date_utc);
      return dt.toLocaleString();
    } else {
      return "";
    }
  };

  // Set image depending upon what images are available
  const setImageSrc = () => {
    if (launchData.length > 0) {
      if (displayData.links.flickr.original[0]) {
        return (
          <Image
            className="launch-img"
            src={displayData.links.flickr.original[0]}
            alt={"Photo of the launch of Space X flight number " + displayData.flight_number}
            fluid
          />
        );
      } else if (displayData.links.patch.large) {
        return (
          <Image
            className="launch-img"
            src={displayData.links.patch.large}
            alt={"Space X patch for flight number " + displayData.flight_number}
            fluid
          />
        );
      } else {
        return <Image className="launch-img" src={spaceXLogo} alt="Space X logo" fluid />;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {launchData.length > 0 ? (
        <Row className="launch-display">
          <Col xs={12} lg={7}>
            <h2>Flight Number {displayData.flight_number}</h2>
            <p>{setDate()}</p>

            <p className="result">
              This launch was a{" "}
              {displayData.success ? (
                <span className="success">success</span>
              ) : (
                <span className="failure">failure</span>
              )}
              .
            </p>
            <p>{displayData.details ? displayData.details : "No details available."}</p>

            {selectedRocket ? <RocketModal selectedRocket={selectedRocket} /> : ""}
          </Col>
          <Col xs={12} lg={5}>
            {setImageSrc()}
          </Col>
        </Row>
      ) : (
        <div className="launch-display">
          <p>Data Loading...</p>
        </div>
      )}
    </>
  );
};
export default LaunchDisplay;
