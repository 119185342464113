import axios from "axios";
const url = "https://api.spacexdata.com/v4/rockets/";

export default class rocketApiClient {
  async getRocket(id) {
    return await axios({
      method: "get",
      url: `${url}${id}`,
    }).catch((error) => {
      throw error;
    });
  }
}
