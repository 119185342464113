import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

const RocketModal = ({ selectedRocket }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="rocket-btn" variant="dark" onClick={handleShow}>
        See Rocket Info
      </Button>

      <Modal className="rocket-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Rocket Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{selectedRocket.name}</h4>
          <p>{selectedRocket.description}</p>
          <Row>
            <Col>
              <p>
                Height: {selectedRocket.height.meters}m ({selectedRocket.height.feet}ft)
              </p>
              <p>
                Mass: {selectedRocket.mass.kg}kg ({selectedRocket.mass.lb}lb)
              </p>
            </Col>
            <Col>
              <p>
                Diameter: {selectedRocket.diameter.meters}m ({selectedRocket.diameter.feet}ft)
              </p>
              <p>Cost per launch: ${selectedRocket.cost_per_launch}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RocketModal;
