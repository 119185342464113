import axios from "axios";
const url = "https://api.spacexdata.com/v4/launches";

export default class spaceXApiClient {
  async getLaunchData() {
    return await axios({
      method: "get",
      url: url,
    }).catch((error) => {
      throw error;
    });
  }
}
