import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import spaceXApiClient from "./api/spaceXApiClient";
import rocketApiClient from "./api/rocketApiClient";
import LaunchSelector from "./components/LaunchSelector";
import LaunchDisplay from "./components/LaunchDisplay";
import { Container, Row } from "react-bootstrap";

function App() {
  const [launchData, setLaunchData] = useState([]);
  const [selectedLaunch, setSelectedLaunch] = useState([]);
  const [selectedRocket, setSelectedRocket] = useState(null);

  const launchesApiClient = useMemo(() => new spaceXApiClient(), []);

  // Get launch data from spaceX API
  const requestLaunchData = useCallback(async () => {
    let newLaunchData = await launchesApiClient.getLaunchData();
    newLaunchData = newLaunchData.data;

    // Remove upcoming launches
    let pastLaunches = newLaunchData.filter((launch) => {
      if (!launch.upcoming) {
        return launch;
      }
      return null;
    });

    pastLaunches.reverse();
    setLaunchData(pastLaunches);
  }, [launchesApiClient]);

  useEffect(() => {
    requestLaunchData();
  }, [requestLaunchData]);

  // Get selected rocket data

  useEffect(() => {
    const singleRocketApiClient = new rocketApiClient();

    if (selectedLaunch.rocket !== undefined) {
      const requestRocketData = async (rocketId) => {
        let newRocketData = await singleRocketApiClient.getRocket(rocketId);
        setSelectedRocket(newRocketData.data);
      };

      requestRocketData(selectedLaunch.rocket);
    }
  }, [selectedLaunch]);

  return (
    <div className="App">
      <main>
        <Container>
          <Row className="justify-content-center text-center">
            <h1>Space X Launch Data</h1>
          </Row>
          <LaunchSelector
            launchData={launchData}
            setSelectedLaunch={setSelectedLaunch}
            selectedLaunch={selectedLaunch}
          />
          <LaunchDisplay
            launchData={launchData}
            selectedLaunch={selectedLaunch}
            selectedRocket={selectedRocket}
          />
        </Container>
      </main>
    </div>
  );
}

export default App;
