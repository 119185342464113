import { Form, Row, Col } from "react-bootstrap";

const LaunchSelector = ({ launchData, setSelectedLaunch }) => {
  const launchListBuilder = (launchData) => {
    if (launchData.length > 0) {
      return launchData.map((launch) => {
        return (
          <option key={launch.id} value={launch.id}>
            Flight Number {launch.flight_number}
          </option>
        );
      });
    } else {
      return <option>No Data Available</option>;
    }
  };

  const handleChange = (e) => {
    let value = document.getElementById("selector").value;

    // Find this value in launch data and set selected launch
    if (launchData.length > 0) {
      launchData.find((launch) => {
        if (launch.id === value) {
          setSelectedLaunch(launch);
          return launch;
        }
        return null;
      });
    }
  };

  return (
    <Row className="justify-content-center text-center">
      <Col xs={12} lg={4} className="launch-selector justify-content-center">
        <Form.Select
          className="text-center"
          name="selector"
          id="selector"
          aria-label="select-flight-number"
          onChange={(e) => handleChange(e)}
        >
          <option key="0" value="0">
            Select Launch
          </option>
          {launchListBuilder(launchData)}
        </Form.Select>
      </Col>
    </Row>
  );
};
export default LaunchSelector;
